import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { store, history } from "./app/store";
import { Provider } from "react-redux";
import App from "./App";
import { OidcProvider, loadUser } from "redux-oidc";
import { ConnectedRouter } from "connected-react-router";
import SilentRenew from "./Components/SilentRenew";
import userManager from "./Components/Utilities/userManager";
import Callback from "./Components/Callback";
import * as serviceWorker from "./serviceWorker";

loadUser(store, userManager);
export const AppVersion = "5.0.0";

ReactDOM.render(
	<BrowserRouter>
		<Switch>
			<Route path="*">
				<Provider store={store}>
					<OidcProvider userManager={userManager} store={store}>
						<ConnectedRouter history={history}>
							<Route path={"/silent_renew"} exact={true} component={SilentRenew} />
							<Route path="/" component={App} />
							<Route path="/callback" exact={true} component={Callback} />
						</ConnectedRouter>
					</OidcProvider>
				</Provider>
			</Route>
		</Switch>
	</BrowserRouter>,
	document.getElementById("page-wrapper")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
