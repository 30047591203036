import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ThemeLoader from "../Components/Widgets/ThemeLoader";

// lazy-load home components
const SectionRoutes = lazy(() => import("./Main/SectionRoutes"));
const NoMatch = lazy(() => import("./NoMatch"));

const Main = () => {
	return (
		<Suspense fallback={<ThemeLoader />}>
			<Switch>
				<Route path="/">
					<SectionRoutes />
				</Route>
				<Route exact={true} path="">
					<Redirect to="/" />
				</Route>
				<Route exact={true} path="/callback">
					<Redirect to="/" />
				</Route>
				<Route component={NoMatch} />
			</Switch>
		</Suspense>
	);
};

export default Main;
